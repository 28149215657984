import React, { useState } from "react";
import { Container, Row, Col, Button, Form, Modal  } from 'react-bootstrap';


const LoginForm = ({ onSubmit }) => {

    return (
        <div className="_button-wrapper _full_width">

<p className="text-center">
                Dapatkan Bonus Spesial Ini, Jika Daftar VIP Waiting List Sebelum 28 Februari
                - 3 Video Series dan Disc Up To 60% Early Bird Attraction Mastery
            </p>

            <Form method="POST" action="https://mrjose.activehosted.com/proc.php">
            <input type="hidden" name="u" value="77" />
            <input type="hidden" name="f" value="77" />
            <input type="hidden" name="s" />
            <input type="hidden" name="c" value="0" />
            <input type="hidden" name="m" value="0" />
            <input type="hidden" name="act" value="sub" />
            <input type="hidden" name="v" value="2" />

            <Form.Group controlId="formBasicPassword">
                <Form.Label>Nama Lengkap : </Form.Label>
                <Form.Control type="text" name="fullname" placeholder="Wajib" required/>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
                <Form.Label>Email Aktif:</Form.Label>
                <Form.Control type="email" name="email" placeholder="Wajib" required/>
            </Form.Group>
            <Button className="__color-outline" type="submit" size="lg" block >
                Submit
            </Button>
            </Form>

        </div>
    );
};

export default function Seven(){

    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const onLoginFormSubmit = (e) => {
      e.preventDefault();
      handleClose();
    };
    return (
        <>
           <Container fluid className="__bg-primary-miror">
              
                <Container className="fade-in">
                    <Row className="justify-content-center">
                        <h2 className="__text-headline text-white text-center  __margin-bottom-5-wl">
                            “Pria Hanya Suka Wanita Cantik dan Seksi!” <br/>
                            Benarkah Seperti Itu?  
                        </h2>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className="__text-desc">
                                <p className="text-center text-white">
                                    Pernahkah kamu berfikir kebanyakan pria menyukai wanita karena fisiknya cantik dan menarik? 
                                </p>
                                <p className="text-center text-white">
                                    Benarkah begitu? <br/>
                                    Jawabannya adalah….. YUP! BENAR! Hahaha.
                                </p>
                                <p className="text-center text-white">
                                    Memang benar pria suka wanita yang cantik dan menarik.
                                </p>
                                <p className="text-center text-white">
                                    <b>TAPI SIALNYA..</b>
                                    Hal itu bukanlah satu-satunya cara membuat pria tertarik dengan kamu.
                                </p>
                                <p className="text-center text-white">
                                    Sementara, kenyataannya banyak alumni love training kami yang tidak unggul fisik tetapi tetap bisa memiliki kehidupan romansa yang bahagia. 
                                </p>
                                <p className="text-center text-white">
                                    Mengapa begitu? <br/>
                                    Karena mereka <b>paham cara memperlihatkan sisi terbaik </b>mereka dengan <b>teknik menanam benih cinta di otak pria yang mereka suka hingga buat pria </b>tertarik gak cuma dari kecantikan fisik.
                                </p>
                                <p className="text-center text-white">
                                    Bukankah kamu ingin pria incaranmu membalas perasaanmu juga?
                                </p>
                                <p className="text-center text-white">
                                    Ladies, jika kamu ingin merasakan apa yang para alumni kami rasakan, <br/>
                                    <b>Sekarang</b> di hadapanmu ada jalan pintas agar benih ketertarikan yang kamu tebar bisa tumbuh subur <br/> pada pria yang tepat!
                                </p>
                                <p className="text-center text-white">
                                    <b>Klik Join Waiting List untuk dapatkan kehidupan romansa yang kamu impikan!</b>
                                </p>
                                <div className="d-flex justify-content-center">
                                    <Button variant="primary" size="lg" onClick={handleShow}>
                                        JOIN WAITING LIST
                                    </Button>{' '}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    
                </Container>
            </Container>

            <Modal 
            
            show={show} onHide={handleClose} 
            size="md"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="__text-wl-title">
                        Join Waiting List
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="__modal-body-bg">
                    <LoginForm onSubmit={onLoginFormSubmit} />
                </Modal.Body>
                
            </Modal>

        </>
    )
}